@import "~adminator/src/assets/styles/index";
@import "./auth.scss";
@import "./sidebar.scss";
@import "./gap.scss";
@import "./custom.scss";
@import "~daterangepicker/daterangepicker.css";

.text-underline {
  text-decoration: underline;
}

#loader {
  transition: all 0.3s ease-in-out;
  opacity: 1;
  visibility: visible;
  position: fixed;
  height: 100vh;
  width: 100%;
  background: #fff;
  z-index: 90000;
}

.fadeOut {
  opacity: 0;
  visibility: hidden;
}

.spinner {
  width: 40px;
  height: 40px;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.text-right {
  text-align: right;
}

.w-sm {
  min-width: 768px;
  max-width: 992px;
}

.daterangepicker {
  .applyBtn {
    color: white;
  }
}

.logo-text {
  font-weight: bold;
}

.sidebar-logo {
  padding: 3px 20px !important;
}

.btn-primary {
  &:visited, &:focus, &:active, &:disabled {
    color: white;
  }
}

.input-icons {
  position: relative;
  width: calc(100% - 68px);

  input {
    width: 100%;
    box-sizing: border-box;
    padding-left: 40px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  i {
    position: absolute;
    padding: 10px 10px;
  }
}

.custom-row {
  --bs-gutter-x: 10px;
}

.invoice-preview {
  width: 80%;
  min-height: 650px;
  border: none;
}

.table-content-center {
  tr {
    td {
      text-align: center;
    }
  }
}

@mixin sticky-shadow {
  box-shadow: inset 10px 0 8px -8px #00000026;
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  transform: translate(100%);
  transition: box-shadow 0.3s;
  content: "";
  pointer-events: none;
}

#sales-chart {
  min-height: 100px;
}

#sales-table {
  div {
    width: 100%;
    overflow-x: scroll;
    position: relative;
  }

  thead th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
    background: #f9fafb;
  }

  .table-cell-fix-left--scrolled,
  .table-cell-fix-left--unscrolled {
    left: 0;
    z-index: 1;
    min-width: 170px;
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    background-color: #f9fafb;
  }

  .table-cell-fix-left--scrolled {
    &:after {
      @include sticky-shadow();
    }

    &:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 100%;
      background: rgba(0, 0, 0, 0.0625);;
      left: -1px;
      top: 0;
    }
  }
}
