@import '~adminator/src/assets/styles/spec/settings/materialColors';
@import '~adminator/src/assets/styles/spec/settings/baseColors';

.sidebar-menu {
  .nav-item {
    position: relative;

    .active, .sidebar-link:hover {
      color: $grey-900;

      &:after {
        position: absolute;
        content: "";

        width: 2px;
        top: 0;
        right: 0;
        height: 100%;
        background-color: $md-green-500;
      }
    }
  }
}
