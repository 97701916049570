@import '~adminator/src/assets/styles/spec/settings/materialColors';
@import '~adminator/src/assets/styles/spec/settings/baseColors';

$auth-header-height: 50px;

.auth-header {
  background-color: $default-info;
  height: $auth-header-height;
  line-height: $auth-header-height;
  color: white;
  font-weight: 600;
  padding-left: 24px;
}

.auth-form-wrapper,
.reset-password-form-wrapper {
  position: absolute;
  top: calc(50% - #{$auth-header-height});
  transform: translateY(-50%);
}

.auth-form-wrapper {
  min-width: 468px;
}

.reset-password-form-wrapper {
  min-width: 568px;

  .form-label {
    font-size: 12px !important;
  }
}

.form-header {
  margin-bottom: 16px;
  p {
    font-weight: bold;
    margin: 0;
  }
}
