#flexSwitchCheckDefault {
  height: 65%;
  width: 4em;
}
#flexSwitchEthosFlag {
  padding-left: 3.5em;
}
.datepicker {
  padding: 0.375rem 0.75rem;
}
#flexSwitchEthosFlagShow {
  width: 4em;
  height: 80%;
  margin: 1px;
}
.contract_lastest_available_date_to {
  width: 247px;
  height: 35px;
  margin-left: 10px;
  padding: 6px !important;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  span {
    padding-top: 2px;
  }
}

.user-page {
  .float-right {
    float: right;
  }
}

.w-30p {
  width: 30% !important;
}

.form-coporate-profile {
  .col-sm-3 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.m-inherit {
  margin: inherit;
}

.text-overflow-ellipsis {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &__width-10-ch {
    max-width: 10ch;
  }
}

.bottom-5 {
  bottom: 5% !important;
}

#import-data-info {
  margin-block-start: 150px;

  .content-import-data-failed {
    max-width: 100%;
    max-height: 150px;
    margin-inline-start: 100px;
    margin-inline-end: 100px;
    padding-inline-start: 10px !important;

    p {
      text-align: left;
    }
  }

  .btn-close-modal:hover {
    background-color: #e7e7e7;
  }

  .modal-fit-content {
    border-radius: 0.3rem;
    box-shadow: 0 0 5rem rgba(0, 0, 0, 0.25);
    border: none;
  }

  .btn-close-modal {
    min-width: 0rem !important;
    background: #d0d0d0;
    margin-inline-end: 10px;
    border-color: #ced4da;
    border-radius: 0.35rem;
  }
}

#loader-ffs {
  transition: all 0.3s ease-in-out;
  opacity: 1;
  visibility: visible;
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 90000;
}
/*Hidden class for adding and removing*/
.lds-dual-ring.hidden {
  display: none;
}

/*Add an overlay to the entire page blocking any further presses to buttons or other elements.*/
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
  opacity: 1;
  transition: all 0.5s;
}

/*Spinner Styles*/
.lds-dual-ring {
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 5% auto;
  border-radius: 50%;
  border: 6px solid rgb(13, 13, 100);
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.table-container {
  overflow-x: auto;
  .table-scroll {
    width: 100%;
    white-space: nowrap;
  }
  .fixed-column {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #f9fafb;
  }
}

.table-container {
  overflow-x: auto;
  .table-scroll {
    width: 100%;
    white-space: nowrap;
  }
  .fixed-column {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #f9fafb;
  }
}
